import { PostRequestJson } from "src/utils/rest_api_util";
import { config } from "src/config/config";
import { MvpApiVersion } from "./api_types";

export const buyAssetAPI = (options: any) => {
  let url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.buy_asset}`;
  if (options.amountToPay === 0) {
    url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.buy_asset_for_free}`;
  }
  let headers: Record<string, any> = {
    Authorization: `Bearer ${options.accessToken}`,
  };

  if (options.mvpVersion === MvpApiVersion.MVP_V20) {
    headers["MvpVersion"] = MvpApiVersion.MVP_V20;
  }

  let data = {
    owner_account_id: options.ownerAccountId,
    kid: options.kid,
    asset_token_id: options.asset_token_id,
    balance_to_use: options.balanceToUse,
    asset_sale_price: options.assetSalePrice,
    lang: options.lang,
  };
  return PostRequestJson(url, data, headers);
};

export const buyGroupAssetsAPI = (options: any) => {
  const url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.buy_group_assets}`;
  const headers: Record<string, any> = {
    Authorization: `Bearer ${options.accessToken}`,
  };

  const data = {
    number_of_purchases: options.numberOfPurchases,
    group_id: options.groupId,
  };
  return PostRequestJson(url, data, headers);
};

export const rentAssetAPI = (options: any) => {
  let url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.rent_asset}`;
  if (options.amountToPay === 0) {
    url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.rent_asset_for_free}`;
  }
  const headers: Record<string, any> = {
    Authorization: `Bearer ${options.accessToken}`,
  };

  const data = {
    owner_account_id: options.ownerAccountId,
    kid: options.kid,
    asset_token_id: options.assetTokenID,
    balance_to_use: options.balanceToUse,
    asset_rent_price: options.assetRentPrice,
    lang: options.lang,
  };
  return PostRequestJson(url, data, headers);
};

export const tradeAssetAPI = (options: any) => {
  let url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.trade_asset}`;
  let headers: Record<string, any> = {
    Authorization: `Bearer ${options.accessToken}`,
  };

  if (options.mvpVersion === MvpApiVersion.MVP_V20) {
    headers["MvpVersion"] = MvpApiVersion.MVP_V20;
  }

  let data = {
    my_trading_asset_token_id: options.myTradingSerialId,
    offering_serial_token_id: options.requestedSerialId,
    offering_serial_owner_account_id: options.requestedSerialOwnerAccountId,
  };
  return PostRequestJson(url, data, headers);
};

export const cancelPurchaseAPI = (options: any) => {
  let url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.cancel_purchase}`;
  let headers: Record<string, any> = {
    Authorization: `Bearer ${options.accessToken}`,
  };

  let data = {
    kid: options.kid,
    serial_number: options.serialNumber,
  };
  return PostRequestJson(url, data, headers);
};

export const bidAssetAPI = (options: any) => {
  const url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.bid_asset}`;
  let headers: Record<string, any> = {
    Authorization: `Bearer ${options.accessToken}`,
  };

  if (options.mvpVersion === MvpApiVersion.MVP_V20) {
    headers["MvpVersion"] = MvpApiVersion.MVP_V20;
  }

  let data = {
    asset_token_id: options.assetTokenID,
    owner_account_id: options.ownerAccountId,
    bid_amount: options.bidAmount,
  };
  return PostRequestJson(url, data, headers);
};
