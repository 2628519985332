import { config } from "src/config/config";
import { AjaxStatus } from "src/service/api_types";

export const PAYMENT_ACTIONS = {
  GET_PAYMENT_TOKEN: "GET_PAYMENT_TOKEN",
  INIT_GET_PAYMENT_TOKEN: "INIT_GET_PAYMENT_TOKEN",
  START_GET_PAYMENT_TOKEN: "START_GET_PAYMENT_TOKEN",
  SHOW_PAYMENT_MODAL: "SHOW_PAYMENT_MODAL",
};

export const getPaymentToken = (data: any) => ({
  type: PAYMENT_ACTIONS.GET_PAYMENT_TOKEN,
  data,
});

export const initGetPaymentToken = () => ({
  type: PAYMENT_ACTIONS.INIT_GET_PAYMENT_TOKEN,
});

export const startGetPaymentToken = () => ({
  type: PAYMENT_ACTIONS.START_GET_PAYMENT_TOKEN,
});

export const showPaymentModalDispatch = (show: any) => {
  return async (dispatch: Function) => {
    let data = show;
    dispatch({
      type: PAYMENT_ACTIONS.SHOW_PAYMENT_MODAL,
      data,
    });
  };
};

export const getPaymentTokenDispatch =
  (params: any) => (dispatch: Function) => {
    dispatch(startGetPaymentToken());
    dispatch(
      getPaymentToken({
        token: params.token,
      })
    );
  };
