const common_config = {
  APP_DEBUG: JSON.parse(process.env.REACT_APP_APP_DEBUG as string),
  USE_MOCK_DATA: JSON.parse(process.env.REACT_APP_USE_MOCK_DATA as string),
  USE_MOCK_ASSETS: JSON.parse(process.env.REACT_APP_USE_MOCK_ASSETS as string),
  DEFAULT_USER_ROLE: process.env.REACT_APP_DEFAULT_USER_ROLE,
  AUTH_WITH_FCL: JSON.parse(process.env.REACT_APP_AUTH_WITH_FCL as string),
  MARKETPLACE_ID: process.env.REACT_APP_MARKETPLACE_ID,
  SUPPORT_MULTI_COPIES: JSON.parse(
    process.env.REACT_APP_SUPPORT_MULTI_COPIES as string
  ),
  APP_ENV: process.env.REACT_APP_APP_ENV,
  SUPPORT_RENT: JSON.parse(process.env.REACT_APP_SUPPORT_RENT as string),
  SUPPORT_ALL_OWNER_LIST: JSON.parse(
    process.env.REACT_APP_SUPPORT_ALL_OWNER_LIST as string
  ),
};

const api_config = {
  AJAX_TIMEOUT_INFINITE: 0,
  AJAX_GET_TIMEOUT: 0,
  AJAX_POST_TIMEOUT: 0,
  AJAX_DELETE_TIMEOUT: 0,
  AJAX_PUT_TIMEOUT: 0,
  AJAX_PATCH_TIMEOUT: 0,

  MARKETPLACE_API: {
    HOST: process.env.REACT_APP_MARKETPLACE_API_HOST,
    MVP_VERSION: process.env.REACT_APP_TRM_MVP_VERSION,
    APIS_V2: {
      init_account: "/accounts/initialize",
      current_account: "/accounts/current",
      current_notifications: "/notifications",
      notification_summary: "/notifications/summary",
      current_transactions: "/accounts/current/transactions",
      current_bids: "/accounts/current/bids",
      current_balance: "/accounts/current/balance",
      assets: "/assets",
      listed_assets: "/assets/listed",
      listed_assets_per_serial: (kid: string) => `/assets/listed/${kid}`,
      listed_created_assets: "/assets/listed/created",
      listed_owned_assets: "/assets/listed/owned",
      owned_assets: "/assets/owned",
      owned_kid_assets: (kid: string) => `/assets/owned/${kid}`,
      owned_kid_assets_per_serial: (kid: string) =>
        `/assets/owned/${kid}/serials`,
      trade_listing_serial: (kid: string) =>
        `/assets/${kid}/trade_listing_serials`,
      trade_listing_asset_token_id: (kid: string) =>
        `/assets/${kid}/trade_listing_asset_token_id`,
      owned_content_brands: "/owned_contents/brands",
      owned_content_brand: (key) => `/owned_contents/brands/${key}`,
      created_assets: "/assets/created",
      created_kid_assets: (kid: string) => `/assets/created/${kid}`,
      created_kid_assets_per_serial: (kid: string) =>
        `/assets/created/${kid}/serials`,
      rented_assets: "/assets/rented",
      rented_kid_assets: (kid: string) => `/assets/rented/${kid}`,
      liked_assets: "/assets/owned",
      bidding_assets: "/assets/bidding",
      assets_of_upload: "/assets/uploaded",
      update_asset: "/assets/update",
      list_asset: "/assets/list",
      list_asset_x: "/assets/list_x",
      delist_asset: "/assets/delist",
      offer_trading_asset: "/assets/offer_trading_asset",
      copy_asset: "/assets/copy",
      copy_asset_x: "/assets/copy_x",
      distribute_asset: "/free_distribution_settings",
      calc_free_distribution_fee: "/free_distribution_rights/calc_price",
      buy_asset: "/assets/purchase",
      buy_group_assets: "/assets/randomly_purchase",
      buy_asset_for_free: "/assets/purchase_for_free",
      rent_asset_for_free: "/assets/rent_for_free",
      rent_asset: "/assets/rent",
      trade_asset: "/assets/trade",
      cancel_purchase: "/purchase/cancel",
      bid_asset: "/assets/bid",
      complete_auction: "/assets/auction/complete",
      delete_asset: "/assets/delete",
      drm_token: "/assets/drm_token",
      read_notification: (notifyId: string) =>
        `/notifications/${notifyId}/read`,
      account_info: (accountId: string) => `/accounts/info/${accountId}`,
      asset_by_id: (assetId: string) => `/assets/${assetId}`,
      listed_asset_by_id: (assetId: string) => `/assets/listed/${assetId}`,
      assets_rented_by_id: (assetId: string) =>
        `/assets/listed/${assetId}/rented`,
      asset_transactions: (assetId: string) =>
        `/assets/${assetId}/transactions`,
      asset_bids: (assetId: string) => `/assets/${assetId}/bids`,
      asset_bid_summary: (assetId: string) => `/assets/${assetId}/bid_summary`,
      mint_status: (transactionId: string) =>
        `/assets/mint_status/${transactionId}`,
      flow_tx_status: (transactionId: string) =>
        `/assets/flow_status/${transactionId}`,
      deposit_usdc: "/deposit/usdc",
      deposit_cash: "/deposit/cash",
      create_brand: "/brands",
      brands: "/brands",
      update_brand: "/brands/update",
      brand: (account: string) => `/brands/${account}`,
      brand_created_assets: "/brands/created_assets",
      brand_sale_assets: "/brands/sale_assets",
      brand_rental_assets: "/brands/rental_assets",
      brand_groups: (account: string) => `/brands/${account}/groups`,
      group_by_encrypted_id: (groupEncryptedId: string) =>
        `/groups/${groupEncryptedId}`,
      point_details: "/point_details",
      point_balance: "/point_balance",
      register_bank: "/banks",
      bank: "/bank",
      acquisition_restriction: "/acquisition_restriction",
      payout_requests_details: "/payout_requests/details",
      payout_requests: "/payout_requests",
      cancel_payout_request: "/payout_requests/cancel",
      export_payout_requests: "/payout_requests/export",
      update_statuses: "/payout_requests/update_statuses",
      check_update_status: "/payout_requests/check_update_status",
      users: "/users",
      accounting_data: "/transactions",
      create_group: "/groups",
      get_groups: "/groups",
      update_group: (group_key) => `/groups/${group_key}`,
      contents_of_group: (group_key) => `/groups/${group_key}/contents`,
      get_user_local_currency: "/currencies/user_local_currency",
      trading_assets: "/assets/trading_assets",
      tradable_listed_assets: "/assets/tradable_listed_assets",
      trade_related_serials_data: (key: string) =>
        `/assets/trade_related_serials_data/${key}`,
      trade_offer_histories: "/trade_offer_logs",
      application_settings: "/application_settings",
      showing_menu_settings: "/showing_menu_settings",
      coupon_details: "/coupons",
      coupons: (coupon_code) => `/coupons/${coupon_code}`,
      coupon_occupations: (coupon_code) =>
        `/coupons/${coupon_code}/occupations`,
    },
  },

  MEDIA_UPLOAD_API: {
    HOST: process.env.REACT_APP_MARKETPLACE_API_HOST,
    CHUNK_SIZE: 300 * 1024 * 1024,
    CHUNK_RETRY_COUNT: 3,
    CHUNK_UPLOAD_TIMEOUT: 300000,
    PARALLEL_CHUNKS: 2,
    APIS: {
      asset_upload: "/upload/asset",
      assets_status: "/upload/status",
      asset_delete: "/upload/delete_asset",
      asset_chunk_url: "/upload/chunk_url",
      complete_upload: "/upload/complete",
    },
  },

  PROFIT_CALCULATE_API: {
    HOST: process.env.REACT_APP_MARKETPLACE_API_HOST,
    APIS: {
      profit_calculate: "/profit_calculate",
    },
  },
};

const ui_config = {
  MARKETPLACE_UI: {
    ASSET_PAGE_SIZE: 16,

    REFRESH_INTERVAL: {
      CREATE_STATUS: 5000,
      NEW_DROPS: 5000,
      MARKETPLACE: 30000,
      COLLECTED: 30000,
      CREATED: 30000,
      RENTED: 30000,
      BIDS: 5000,
      FAVORITED: 5000,
      BY_CREATOR: 5000,
      ACCOUNT_TX: 5000,
      FLOW_ACCOUNT: 5000,
      NOTIFICATIONS: 5000,
      FLOW_STATUS: 5000,
      INDEX_DATA: 5000,
    },
  },
};

const sports_config = {
  SPORTS_CONFIG: {
    TOTAL_TEAMS: 15,
    PLAYERS_PER_TEAMS: 10,
    TOTAL_PLAYERS: 150,
  },
};

const drm_config = {
  DRM_CONFIG: {
    FP_CERT_URL:
      common_config.APP_ENV === "production"
        ? "/cert/fairplay.cer"
        : "/cert/fairplay_trm_product_test.cer",
  },
};

const flow_config = {
  FLOW_CONFIG: {
    // 参考ページは以下。
    // https://docs.blocto.app/blocto-sdk/javascript-sdk/flow/migration-guide
    "accessNode.api":
      common_config.APP_ENV === "production"
        ? "https://rest-mainnet.onflow.org"
        : "https://rest-testnet.onflow.org",
    "discovery.wallet":
      common_config.APP_ENV === "production"
        ? "https://wallet-v2.blocto.app/-/flow/authn"
        : "https://wallet-v2-dev.blocto.app/-/flow/authn",
  },
};

const payment_config = {
  PAYMENT_CONFIG: {
    STRIPE_PUBLIC_KEY: "",
  },
};

// 最終的にはconstants.tsとして隔離したい
const windowBrakepoint = {
  SM_WIDTH_BRAKEPOINT: 640,
};

export const config = {
  ...common_config,
  ...api_config,
  ...ui_config,
  ...sports_config,
  ...drm_config,
  ...flow_config,
  ...payment_config,
  ...windowBrakepoint,
};
