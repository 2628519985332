import { config } from "src/config/config";
import { AjaxStatus } from "src/service/api_types";
import {
  bidAssetAPI,
  buyAssetAPI,
  buyGroupAssetsAPI,
  rentAssetAPI,
  tradeAssetAPI,
} from "src/service/order_api";
import { loadStripe } from "@stripe/stripe-js";

export const ORDER_ACTIONS = {
  BUY_ASSET: "BUY_ASSET",
  INIT_BUY_ASSET: "INIT_BUY_ASSET",
  BUY_GROUP_ASSETS: "BUY_GROUP_ASSETS",
  INIT_BUY_GROUP_ASSETS: "INIT_BUY_GROUP_ASSETS",
  RENT_ASSET: "RENT_ASSET",
  INIT_RENT_ASSET: "INIT_RENT_ASSET",
  TRADE_ASSET: "TRADE_ASSET",
  INIT_TRADE_ASSET: "INIT_TRADE_ASSET",
  INIT_BID_ASSET: "INIT_BID_ASSET",
  BID_ASSET: "BID_ASSET",
};

export const buyAsset = (data: any) => ({
  type: ORDER_ACTIONS.BUY_ASSET,
  data,
});

export const initBuyAsset = () => ({
  type: ORDER_ACTIONS.INIT_BUY_ASSET,
});

export const buyGroupAssets = (data: any) => ({
  type: ORDER_ACTIONS.BUY_GROUP_ASSETS,
  data,
});

export const initBuyGroupAssets = () => ({
  type: ORDER_ACTIONS.INIT_BUY_GROUP_ASSETS,
});

export const rentAsset = (data: any) => ({
  type: ORDER_ACTIONS.RENT_ASSET,
  data,
});

export const initRentAsset = () => ({
  type: ORDER_ACTIONS.INIT_RENT_ASSET,
});

export const tradeAsset = (data: any) => ({
  type: ORDER_ACTIONS.TRADE_ASSET,
  data,
});

export const initTradeAsset = () => ({
  type: ORDER_ACTIONS.INIT_TRADE_ASSET,
});

export const initBidAsset = () => ({
  type: ORDER_ACTIONS.INIT_BID_ASSET,
});

export const bidAsset = (data: any) => ({
  type: ORDER_ACTIONS.BID_ASSET,
  data,
});

export const buyAssetDispatch = (params: any) => (dispatch: Function) => {
  let options = {
    mvpVersion: params.mvpVersion,
    accessToken: params.accessToken,
    ownerAccountId: params.ownerAccountId,
    kid: params.kid,
    asset_token_id: params.asset_token_id,
    balanceToUse: params.balanceToUse,
    amountToPay: params.amountToPay,
    assetSalePrice: params.assetSalePrice,
    lang: params.lang,
  };

  let stripePromise;
  const getStripe = (publishable_key) => {
    if (!stripePromise) {
      stripePromise = loadStripe(publishable_key);
    }

    return stripePromise;
  };

  const redirectToCheckout = async (id, publishable_key) => {
    console.log("redirectToCheckout");
    const stripe = await getStripe(publishable_key);
    const { error } = await stripe.redirectToCheckout({ sessionId: id });
    console.log("Stripe checkout error", error);
  };

  return buyAssetAPI(options)
    .done((data, status, xhr) => {
      if (config.APP_DEBUG) {
        console.log("Headers: ", xhr.getAllResponseHeaders());
        console.log("/purchase data", data);
      }
      dispatch(
        buyAsset({
          response: data,
          ajaxStat: AjaxStatus.DONE,
        })
      );
      if (params.amountToPay !== 0) {
        redirectToCheckout(data.id, data.key);
      }
    })
    .fail((err) => {
      if (err.status >= 500) {
        if (config.APP_DEBUG) {
          console.log("InternalServerError in accessing to API");
        }
        dispatch(
          buyAsset({
            response: err.errors,
            ajaxStat: AjaxStatus.INTERNAL_SERVER_ERROR,
          })
        );
      } else if (err.status === 422) {
        if (config.APP_DEBUG) {
          console.log("UnprocessableEntity");
        }
        dispatch(
          buyAsset({
            response: err.errors,
            errorMessages: err.responseJSON.error_messages,
            ajaxStat: AjaxStatus.UNPROCESSABLE_ENTITY,
          })
        );
      } else {
        if (config.APP_DEBUG) {
          console.error("Error in buying asset");
        }
        dispatch(
          buyAsset({
            response: {},
            ajaxStat: AjaxStatus.FAILED,
          })
        );
      }
    });
};

export const buyGroupAssetsDispatch = (params: any) => (dispatch: Function) => {
  const options = {
    accessToken: params.accessToken,
    numberOfPurchases: params.numberOfPurchases,
    groupId: params.groupId,
  };

  let stripePromise;
  const getStripe = (publishable_key) => {
    if (!stripePromise) {
      stripePromise = loadStripe(publishable_key);
    }

    return stripePromise;
  };

  const redirectToCheckout = async (id, publishable_key) => {
    console.log("redirectToCheckout");
    const stripe = await getStripe(publishable_key);
    const { error } = await stripe.redirectToCheckout({ sessionId: id });
    console.log("Stripe checkout error", error);
  };

  return buyGroupAssetsAPI(options)
    .done((data, status, xhr) => {
      if (config.APP_DEBUG) {
        console.log("Headers: ", xhr.getAllResponseHeaders());
        console.log("/randomly_purchase data", data);
      }
      dispatch(
        buyGroupAssets({
          response: data,
          ajaxStat: AjaxStatus.DONE,
        })
      );
      redirectToCheckout(data.id, data.key);
    })
    .fail((err) => {
      if (err.status >= 500) {
        if (config.APP_DEBUG) {
          console.log("InternalServerError in accessing to API");
        }
        dispatch(
          buyGroupAssets({
            response: err.errors,
            ajaxStat: AjaxStatus.INTERNAL_SERVER_ERROR,
          })
        );
      } else if (err.status === 422) {
        if (config.APP_DEBUG) {
          console.log("UnprocessableEntity");
        }
        dispatch(
          buyGroupAssets({
            response: err.errors,
            errorMessages: err.responseJSON.error_messages,
            ajaxStat: AjaxStatus.UNPROCESSABLE_ENTITY,
          })
        );
      } else {
        if (config.APP_DEBUG) {
          console.error("Error in buying asset");
        }
        dispatch(
          buyGroupAssets({
            response: {},
            ajaxStat: AjaxStatus.FAILED,
          })
        );
      }
    });
};

export const rentAssetDispatch = (params: any) => (dispatch: Function) => {
  let options = {
    accessToken: params.accessToken,
    ownerAccountId: params.ownerAccountId,
    kid: params.kid,
    assetTokenID: params.assetTokenID,
    balanceToUse: params.balanceToUse,
    amountToPay: params.amountToPay,
    assetRentPrice: params.assetRentPrice,
    lang: params.lang,
  };

  let stripePromise;
  const getStripe = (publishable_key) => {
    if (!stripePromise) {
      stripePromise = loadStripe(publishable_key);
    }

    return stripePromise;
  };

  const redirectToCheckout = async (id, publishable_key) => {
    console.log("redirectToCheckout");
    const stripe = await getStripe(publishable_key);
    const { error } = await stripe.redirectToCheckout({ sessionId: id });
    console.log("Stripe checkout error", error);
  };

  return rentAssetAPI(options)
    .done((data, status, xhr) => {
      if (config.APP_DEBUG) {
        console.log("Headers: ", xhr.getAllResponseHeaders());
        console.log("Rent asset succeeded: ", data);
      }

      dispatch(
        rentAsset({
          response: data,
          ajaxStat: AjaxStatus.DONE,
        })
      );
      if (params.amountToPay !== 0) {
        redirectToCheckout(data.id, data.key);
      }
    })
    .fail((err) => {
      if (err.status >= 500) {
        if (config.APP_DEBUG) {
          console.log("InternalServerError in accessing to API");
        }
        dispatch(
          rentAsset({
            response: err.errors,
            ajaxStat: AjaxStatus.INTERNAL_SERVER_ERROR,
          })
        );
      } else if (err.status === 422) {
        if (config.APP_DEBUG) {
          console.log("UnprocessableEntity");
        }
        dispatch(
          rentAsset({
            response: err.errors,
            errorMessages: err.responseJSON.error_messages,
            ajaxStat: AjaxStatus.UNPROCESSABLE_ENTITY,
          })
        );
      } else {
        if (config.APP_DEBUG) {
          console.error("Error in renting asset");
        }
        dispatch(
          rentAsset({
            response: {},
            ajaxStat: AjaxStatus.FAILED,
          })
        );
      }
    });
};

export const tradeAssetDispatch = (params: any) => (dispatch: Function) => {
  let options = {
    accessToken: params.accessToken,
    myTradingSerialId: params.myTradingSerialId,
    requestedSerialId: params.requestedSerialId,
    requestedSerialOwnerAccountId: params.requestedSerialOwnerAccountId,
  };

  return tradeAssetAPI(options)
    .done((data, status, xhr) => {
      if (config.APP_DEBUG) {
        console.log("Headers: ", xhr.getAllResponseHeaders());
        console.log("/purchase data", data);
      }
      dispatch(
        tradeAsset({
          response: data,
          ajaxStat: AjaxStatus.DONE,
        })
      );
    })
    .fail((err) => {
      if (err.status >= 500) {
        if (config.APP_DEBUG) {
          console.log("InternalServerError in accessing to API");
        }
        dispatch(
          tradeAsset({
            response: err.errors,
            ajaxStat: AjaxStatus.INTERNAL_SERVER_ERROR,
          })
        );
      } else if (err.status === 422) {
        if (config.APP_DEBUG) {
          console.log("UnprocessableEntity");
        }
        dispatch(
          tradeAsset({
            response: err.errors,
            errorMessages: err.responseJSON.error_messages,
            ajaxStat: AjaxStatus.UNPROCESSABLE_ENTITY,
          })
        );
      } else {
        if (config.APP_DEBUG) {
          console.error("Error in trading asset");
        }
        dispatch(
          tradeAsset({
            response: {},
            ajaxStat: AjaxStatus.FAILED,
          })
        );
      }
    });
};

export const bidAssetDispatch = (params: any) => (dispatch: Function) => {
  let options = {
    mvpVersion: params.mvpVersion,
    accessToken: params.accessToken,
    assetTokenID: params.assetTokenID,
    ownerAccountId: params.ownerAccountId,
    bidAmount: params.bidAmount,
  };

  return bidAssetAPI(options)
    .done((data, status, xhr) => {
      if (config.APP_DEBUG) {
        console.log("Headers: ", xhr.getAllResponseHeaders());
        console.log("Bid asset succeeded: ", data);
      }

      dispatch(
        bidAsset({
          response: data,
          ajaxStat: AjaxStatus.DONE,
        })
      );
    })
    .fail((err) => {
      if (config.APP_DEBUG) {
        console.error("Error in bidding asset");
      }
      dispatch(
        bidAsset({
          response: {},
          ajaxStat: AjaxStatus.FAILED,
        })
      );
    });
};
