import React from "react";
import ReactDOM from "react-dom";
import Promise from "promise-polyfill";
import $ from "jquery";
import configureStore from "src/store/store.js";
import "react-grid-layout/css/styles.css";
import "src/assets/icon/icon.css";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/en";
import "@formatjs/intl-relativetimeformat/locale-data/es";
import "@formatjs/intl-relativetimeformat/locale-data/fr";
import "@formatjs/intl-relativetimeformat/locale-data/it";
import "@formatjs/intl-relativetimeformat/locale-data/zh";
import { LocaleData } from "src/i18l/locales/locale_data";
import AppProvider from "src/components/provider";
import reportWebVitals from "src/utils/reportWebVitals";
import "src/styles/index.less";
import "src/styles/index.css";
import { config } from "src/config/config";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { locizePlugin } from "locize";
import arTranslation from "src/locales/ar/common.json";
import deTranslation from "src/locales/de/common.json";
import enTranslation from "src/locales/en/common.json";
import esTranslation from "src/locales/es/common.json";
import frTranslation from "src/locales/fr/common.json";
import jaTranslation from "src/locales/ja/common.json";
import koTranslation from "src/locales/ko/common.json";
import ptTranslation from "src/locales/pt/common.json";
import zhHansTranslation from "src/locales/zh-Hans/common.json";
import zhHantTranslation from "src/locales/zh-Hant/common.json";

if (!window.Promise) {
  // Polyfill if browser does not have promise.
  window.Promise = Promise;
}

const locale =
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage;
window._usingLocale = locale!;

const languageWithoutRegionCode = locale!.toLowerCase().split(/[_-]+/)[0];
const messages =
  LocaleData[languageWithoutRegionCode as keyof LocaleData] ||
  LocaleData[locale! as keyof LocaleData] ||
  {};

const getBrowserLang = (): string => {
  return (
    (window.navigator.languages && window.navigator.languages[0]) ||
    window.navigator.language
  );
};

const getDefaultLang = (): string => {
  const savedLang: string | null = window.localStorage.getItem("lang");
  if (savedLang) {
    return savedLang;
  } else {
    return getBrowserLang() || "en";
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(locizePlugin)
  .init({
    resources: {
      ar: arTranslation,
      de: deTranslation,
      en: enTranslation,
      es: esTranslation,
      fr: frTranslation,
      ja: jaTranslation,
      ko: koTranslation,
      pt: ptTranslation,
      zhHans: zhHansTranslation,
      zhHant: zhHantTranslation,
    },
    lng: getDefaultLang(),
    fallbackLng: "en",

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    react: {
      // Locize InContext editor のための設定。
      // https://docs.locize.com/more/incontext-editor/migrating-from-the-old-incontext-editor#with-i18next
      bindI18n: "languageChanged editorSaved",
    },
  });

i18n.on("languageChanged", (lang) => {
  window.localStorage.setItem("lang", lang);
  document.documentElement.lang = lang;
});

const store = configureStore();

const main = () => {
  $(() => {
    if (config.APP_DEBUG) {
      window.store = store;
    }

    const rootDiv = document.getElementById("root");

    const renderDom = () => {
      ReactDOM.render(
        <AppProvider store={store} locale={locale} messages={messages} />,
        rootDiv
      );
    };

    renderDom();
    reportWebVitals();
  });
};

main();
